<template>
  <v-main
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-4'"
  >
      <v-responsive
        :style="styles"
        min-height="100vh"
        class="d-flex align-left"
      >
        <router-view />
      </v-responsive>
    <dashboard-core-footer />
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',
    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
    computed: {
      src () {
        return this.srcs[this.$route.path]
      },
      styles () {
        // const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100
        const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 0 : 10
        const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 75 : 50
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },
    },

  }
</script>
